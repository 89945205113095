<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <div v-if="exibirTabela">
        <DataTable
          :headers="headers"
          :endpoint="endpoint"
          :autoSearch="false"
          sortBy="numfatura"
          :sortDesc="true"
          ref="datatable"
          pdf
          csv
          excel
        >
          <template v-slot:statuscobranca="{ row }">
            <Chip
              v-if="row.statuscobranca === 'ABERTO'"
              color="info"
              text="Em aberto"
              :small="true"
            />
            <Chip
              v-if="row.statuscobranca === 'AGUARDANDOPAGAMENTO'"
              color="warning"
              text="Aguardando pagamento"
              :small="true"
            />
            <Chip
              v-if="row.statuscobranca === 'PAGO'"
              color="success"
              text="Pago"
              :small="true"
            />
          </template>
          <template v-slot:totalvendas="{ col }">
            R$ {{ col | formatMoney }}
          </template>
          <template v-slot:totaldescsuperon="{ col }">
            R$ {{ col | formatMoney }}
          </template>
          <template v-slot:valorfatura="{ col }">
            R$ {{ col | formatMoney }}
          </template>
          <template v-slot:idfaturamento="{ row }">
            <FormButton icon @click="() => onClickFatura(row)">
              <v-icon color="primario">fa-info-circle</v-icon>
            </FormButton>
          </template>
        </DataTable>
      </div>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import { mapGetters } from 'vuex'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import Api from '../../service/api-web'
import Chip from '@/components/Chip'
import FormButton from '@/components/form/FormButton'
import { MENU_ALT_TITLE } from '@/store/actions/menu'

export default {
  name: 'PainelFaturas',
  components: {
    Chip,
    DataTable,
    Panel,
    MarketingModules,
    FormButton
  },
  data: () => ({
    headers: [],
    modulosForm: [ModulesEnum.TODOS]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'getEmpresaId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v2/fatura/fornecedor/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    },
    exibirTabela() {
      return this.headers.length !== 0
    }
  },
  created() {
    this.$store.dispatch(MENU_ALT_TITLE, '')
    Api.v4.company.get(this.getEmpresaId).then(resp => {
      if (resp.data.tipofaturamento == 'FAIXA_RECEITA') {
        this.headers = [
          {
            text: 'Número',
            value: 'numfatura',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Vencimento',
            value: 'datavencimento',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Faixa de receita',
            value: 'faixa_receita',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Vendas',
            value: 'qttotalvendas',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Total em Vendas',
            value: 'totalvendas',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Total em descontos',
            value: 'totaldescsuperon',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Status',
            value: 'statuscobranca',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Valor da fatura',
            value: 'valorfatura',
            clickable: false,
            align: 'center'
          }
        ]
      } else {
        this.headers = [
          {
            text: 'Número',
            value: 'numfatura',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Vencimento',
            value: 'datavencimento',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Vendas',
            value: 'qttotalvendas',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Total em Vendas',
            value: 'totalvendas',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Total em descontos',
            value: 'totaldescsuperon',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Status',
            value: 'statuscobranca',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Valor da fatura',
            value: 'valorfatura',
            clickable: false,
            align: 'center'
          },
          {
            text: 'Detalhes',
            value: 'idfaturamento',
            align: 'center',
            sortable: false
          }
        ]
      }
    })
  },
  methods: {
    onClickFatura(fatura) {
      const idfatura = fatura.idfaturamento || 'proxima'
      return this.$router.push({
        path: `/fornecedor/faturas/${idfatura}`,
        query: {
          numfatura: fatura.numfatura
        }
      })
    }
  }
}
</script>
